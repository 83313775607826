<template>
  <div class="main" >
    <!-- body start -->
    <div class="textbox" style="padding: 30px 0 0 20px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/carrier' }"
          >产业载体</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/AllCarriers' }"
          >载体列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>载体详细</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="bodybox" >
      <div class="textbox">
        <div class="box_top">
          <div class="top_left">
            <div class="bigimg">
              <img :src="curImgUrl" alt="" />
            </div>
            <div class="smallimg">
              <div
                class="imgbox"
                v-for="(item, i) in FileList"
                @click="clickimg(item, i)"
                :key="i"
              >
                <img
                  :class="indeximg == i ? 'activeimg' : ''"
                  :src="item.URL"
                  alt=""
                />
                <span></span>
              </div>
            </div>
          </div>

          <div class="top_right" v-if="Obj != null">
            <div class="title">
              {{ Obj.Name }}
              <span @click="getContact(Obj.SysID)">联系载体</span>
            </div>
            <div class="myrow">
              <span class="tab">载体面积:</span>
              <span class="text">{{ Obj.BuildingArea }} m²</span>
              <span class="tab">孵化面积:</span>
              <span class="text">{{ Obj.HatchArea }} m²</span>
            </div>
            <div class="myrow">
              <span class="tab">载体类型:</span>
              <span class="text">{{ Obj.CateName }}</span>
              <span class="tab">载体级别:</span>
              <span
                class="text"
                v-if="
                  Obj.BuildingLevel != null &&
                  Obj.BuildingLevel.indexOf(',') > -1
                "
              >
                <span
                  style="margin-left: 5px"
                  v-for="(tag, i) in Obj.BuildingLevel.split(',')"
                  :key="i"
                  >{{ tag }}</span
                >
              </span>
              <span
                class="text"
                v-if="
                  Obj.BuildingLevel != null &&
                  Obj.BuildingLevel.indexOf(',') == -1
                "
                >{{ Obj.BuildingLevel }}</span
              >
            </div>
            <div class="myrow">
              <span class="tab">入驻企业:</span>
              <span class="tag">{{ Obj.CompanyCount }}所</span>
            </div>
            <div class="myrow">
              <span class="tab">剩余工位:</span>
              <span class="tag">{{ Obj.OverplusCount }}个</span>
            </div>
            <div class="myrow">
              <span class="tab">载体地址:</span>
              <span class="text">{{ Obj.Address }}</span>
            </div>
            <div
              class="myrow"
              v-if="Obj.FollowTag != null && Obj.FollowTag.indexOf(',') != -1"
            >
              <span class="tab">关注领域:</span>
              <span
                class="tags"
                v-for="(tag, t) in Obj.FollowTag.split(',')"
                :key="t"
                >{{ tag }}</span
              >
           
              
            </div>
            <div   class="myrow"
              v-else>
              <span class="tab">关注领域:</span>
                 <span
                class="tags"
               
                >{{ Obj.FollowTag }}</span>
              
              </div>
            <div
              class="myrow"
              v-if="Obj.ServeTag && Obj.ServeTag.indexOf(',') > -1"
            >
              <span class="tab">提供服务:</span>
              <span
                class="tags"
                v-for="(tag, t) in Obj.ServeTag.split(',')"
                :key="t"
                >{{ tag }}</span
              >
            
            </div>
              <div   class="myrow"
              v-else>
              <span class="tab">提供服务:</span>
                 <span
                class="tags"
               
                >{{ Obj.ServeTag }}</span>
              
              </div>
            <div class="myrow">
              <span class="tab">联 系 人 :</span>
              <span class="text">{{ Obj.Contacts }}</span>
            </div>
            <div class="myrow">
              <span class="tab">联系电话:</span>
              <span class="text">{{ Obj.Tel }}</span>
            </div>
          </div>
        </div>
        <div class="box_bottom">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="载体介绍" name="first">
              <div class="htmlbox" v-html="Obj.Intro" ref="content"
          @click="imageEnlargement"></div>
            </el-tab-pane>
            <el-tab-pane label="载体设施" name="second">
              <div class="htmlbox" v-html="Obj.Facility" ref="content"
          @click="imageEnlargement"></div>
            </el-tab-pane>
            <el-tab-pane label="空房出租" name="third">
              <div class="htmlbox" v-html="Obj.RentOut" ref="content"
          @click="imageEnlargement"></div>
            </el-tab-pane>
            <el-tab-pane label="关于我们" name="fourth">
              <div class="htmlbox" v-html="Obj.AboutMe" ref="content"
          @click="imageEnlargement"></div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- body end -->
  </div>
</template>

<script>
// import timeFormat from "../util/time.js";

import "../assets/css/common.css";
export default {
  data() {
    return {
      indeximg: 0,
      activeIndex: "3",
      activeName: "first",
      drawer: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度

      indexaa: 0,

      indexbb: 0,

      input2: "",
      curImgUrl: "",
      Obj: {},
      FileList: [],
      SSFileList: [],
      imgArr:[]
    };
  },

  watch: {
    windowWidth(val) {
      let that = this;
      // console.log("实时屏幕宽度：", val);
      if (val > 990) {
        that.drawer = false;
        that.height = "400px";
      } else if (val <= 900 && val > 400) {
        that.height = "200px";
      } else {
        that.height = "100px";
      }
    },
  },
  methods: {
     imageEnlargement(e) {
   
      if (e.target.nodeName == "IMG") {
        //判断点击富文本内容为img图片
        console.log(e.target.currentSrc);
        this.imgArr[0] = e.target.currentSrc;
        console.log(this.imgArr);

         this.$viewerApi({
          images: this.imgArr
        })
     
      } else {
        console.log("点击内容不为img");
      }
    },

    getContact(SysID) {
      let that = this;
      console.log(SysID);
      let userId = window.localStorage.getItem("userId");
      if (!userId) {
        that.$confirm("请登录后联系", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            that.$router.push("/login");
          })
          .catch(() => {});
      } else {
        console.log("请求接口");
        that.$post("/api/Contact/Edit", { MainSysID: SysID }).then(function (
          res
        ) {
          console.log(res);
          if (res.Result) {
        that.$alert('已联系，工作人员将在2-3个工作日内与您联系', {
          confirmButtonText: '确定',
          callback: () => {
            console.log('succese');
          }})
          } else {
            that.$message.error("联系失败，请稍后再试!");
          }
        });
      }
    },
    GetBuildingObj(id) {
      let that = this;
      that
        .$post("/api/Building/ObjShow", {
          SysID: id,
        })
        .then(function (data) {
          console.log(data);
          if (data.Result) {
            that.Obj = data.Obj.Obj;

            data.Obj.FileList.forEach((v) => {
              v.URL = that.urlOA + v.URL.substr(1);
              that.FileList.push(v);
            });
            data.Obj.SSFileList.forEach((v) => {
              v.URL = that.urlOA + v.URL.substr(1);
              that.FileList.push(v);
            });

            if (that.FileList.length > 0) {
              that.curImgUrl = that.FileList[0].URL;
            }
          } else {
            that.$alert("该载体已下架，请查看其他信息", {
              confirmButtonText: "确定",
              callback: () => {
                that.$router.go(-1);
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickimg(item, i) {
      this.curImgUrl = item.URL;
      this.indeximg = i;
    },
    handleClick() {},
    handleSelect(e) {
      let that = this;
      console.log(e);
      that.$router.push(e);
      setTimeout(() => {
        that.drawer = false;
      }, 500);
    },
    getlogin() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  mounted() {
    console.log(this.urlOA);
    let SysID = this.$route.query.SysID;

    var that = this;
    that.GetBuildingObj(SysID);
    let windowWidth = that.windowWidth;
    console.log(windowWidth);
    if (windowWidth < 900 && windowWidth > 400) {
      that.height = "200px";
    } else if (windowWidth < 400) {
      that.height = "150px";
    }
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
  },
};
</script>


<style scoped  lang="scss">
// 内容区

.textbox {
  width: 1200px;
  margin-left: 50%;
  transform: translateX(-50%);
  //   background-color: #475669;
  // padding-top: 50px;
  overflow: hidden;
}
.box_top {
  padding: 10px;
  // background-color: aquamarine;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  .top_left {
    width: 50%;
    float: left;
    .bigimg {
      height: 300px;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .smallimg {
    height: 80px;
    width: 600px;
    margin-top: 10px;
    .imgbox {
      position: relative;
      float: left;
      height: 80px;
      margin: 5px;
      width: 118px;
      img {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      span {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 99999999;
      }
    }
  }
  .activeimg {
    z-index: 99999999999999;
  }
}
.top_right {
  float: left;
  width: 50%;
  overflow: hidden;
  padding-left: 30px;
  box-sizing: border-box;
  .title {
    // height: 30px;
    line-height: 30px;
    font-size: 22px;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
    span {
      background-color: #3fbcbb;
      color: #fff;
      font-size: 14px;
      font-weight: normal;
      padding: 4px 20px;
      cursor: pointer;
      border-radius: 2px;
      white-space: nowrap;
      margin-left: 20px;
    }
  }
  .myrow {
    // height: 35px;
    line-height: 35px;
    overflow: hidden;
  }
}
.tab {
  font-size: 14px;
  color: #999;
}
.text {
  font-size: 13px;
  color: #222;
  margin: 0 30px 0 10px;
}
.tag {
  // float: left;
  // display: inline-block;
  margin-left: 10px;
  height: 20px;
  padding: 3px 20px;
  background-color: #ed982c;
  color: #fff;
  font-size: 13px;
  border-radius: 3px;
}
.tags {
  // float: left;
  // display: inline-block;
  margin-left: 10px;
  height: 20px;
  padding: 3px 20px;
  border: 1px solid #999;
  color: #000;
  font-size: 13px;
  border-radius: 3px;
  white-space: nowrap;
}
.box_bottom {
  margin-top: 50px;
  width: 880px;
}
.htmlbox {
  width: 800px;
  margin-top: 20px;
  margin-left: 10px;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  // box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
  margin-bottom: 50px;
  ::v-deep p {
    line-height: 30px;
    font-size: 15px;
    color: #555;
  }
  ::v-deep img {
    max-width: 100%;
  }
}
.othernews:hover {
  color: #3273dc;
}
.othernews span {
  display: inline-block;
  height: 30px;
  padding-right: 10px;
  //   padding-top: 10px;
  color: #3273dc;
  font-size: 24px;
  font-weight: 800;
  //   line-height: 30px;
  box-sizing: border-box;
}

.htmlbox >>> ul li {
  line-height: 100px;
}
.aaa {
  display: none;
}
@media (max-width: 1201px) {
  .textbox {
    width: 940px;
  }

  h5 {
    padding: 10px;
    box-sizing: border-box;
  }
}
@media (max-width: 991px) {
  .aaa {
    display: block;
  }

  .bannerbox img {
    height: 100%;
  }
  .main .textbox {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
  }
}
@media (max-width: 590px) {
  // 内容区

  .main .textbox {
    width: 96%;
    margin-left: 50%;
    transform: translateX(-50%);
    //   background-color: #475669;
    padding-top: 10px;
    overflow: hidden;
  }
  .box_top {
    padding: 10px;
    // background-color: aquamarine;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    .top_left {
      width: 100%;
      float: left;
      .bigimg {
        height: 300px;
        width: 100%;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .smallimg {
      height: 80px;
      width: 100%;
      margin-top: 10px;
      .imgbox {
        position: relative;
        float: left;
        height: 60px;
        margin: 1px;
        width: 30%;
        img {
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
        }
        span {
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.7);
          z-index: 99999999;
        }
      }
    }
    .activeimg {
      z-index: 99999999999999;
    }
  }
  .top_right {
    float: left;
    width: 100%;
    overflow: hidden;
    padding-left: 10px;
    box-sizing: border-box;
    .title {
      // height: 30px;
      margin-top: 30px;
      line-height: 30px;
      font-size: 24px;
      font-weight: 700;
      color: #333;
      margin-bottom: 20px;
    }
    .myrow {
      // height: 35px;
      line-height: 35px;
      overflow: hidden;
    }
  }
  .tab {
    font-size: 14px;
    color: #999;
  }
  .text {
    font-size: 13px;
    color: #222;
    margin: 0 30px 0 10px;
  }
  .tag {
    // float: left;
    // display: inline-block;
    margin-left: 10px;
    height: 20px;
    padding: 3px 20px;
    background-color: #3fbcbb;
    color: #fff;
    font-size: 13px;
    border-radius: 3px;
  }
  .tags {
    // float: left;
    // display: inline-block;
    margin-left: 10px;
    height: 20px;
    padding: 3px 20px;
    border: 1px solid #999;
    color: #000;
    font-size: 13px;
    border-radius: 3px;
  }
  .box_bottom {
    margin-top: 50px;
    width: 100%;
  }
  .htmlbox {
    width: 100%;
    margin-top: 20px;
    margin-left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    box-sizing: border-box;
    // box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
    margin-bottom: 50px;
    ::v-deep p {
      line-height: 30px;
      font-size: 15px;
      color: #555;
    }
    ::v-deep img {
      max-width: 100%;
    }
  }
  .othernews:hover {
    color: #3273dc;
  }
  .othernews span {
    display: inline-block;
    height: 30px;
    padding-right: 10px;
    //   padding-top: 10px;
    color: #3273dc;
    font-size: 24px;
    font-weight: 800;
    //   line-height: 30px;
    box-sizing: border-box;
  }

  .htmlbox >>> ul li {
    line-height: 100px;
  }
}
</style>